import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
// Components
import Header from "./components/header/Header";
import SnackBar from "./components/utils/snackbars/Snackbar";
// pages
import Dashboard from "./pages/dashboard/Dashboard";
import Balance from "./pages/balance/Balance";
import Feriados from './pages/feriados/Feriados';
import SerieDeFeriados from './pages/serieDeFeriados/SerieDeFeriados';
import Conciliaciones from "./pages/conciliaciones/Conciliaciones";
import TarjetasEmisores from "./pages/tarjetasEmisores/tarjetasEmisores";
import TarjetasPlasticos from "./pages/tarjetasPlasticos/tarjetasPlasticos";
import TarjetasCoeficientes from "./pages/tarjetasCoeficientes/tarjetasCoeficientes";
import TarjetasTransacciones from "./pages/tarjetasTransacciones/tarjetasTransacciones";
import TarjetasAcreditaciones from "./pages/tarjetasAcreditaciones/tarjetasAcreditaciones";
import TarjetasMarcas from "./pages/tarjetasMarcas/tarjetasMarcas";
import TarjetasConciliaciones from "./pages/tarjetasConciliaciones/tarjetasConciliaciones";
import ConciliacionesGIRE from "./pages/conciliacionGIRE/ConciliacionesGIRE";
import LiquidacionesPrisma from './pages/liquidacionesPrisma/LiquidacionesPrisma';
import PromosMarketing from "./pages/promosMarketing/PromosMarketing";
import LimitesDeCondicionesAFIP from "./pages/limites/Limites";
import ControlLimites from "./pages/controlLimites/ControlLimites";
import ReporteSemanal from "./pages/reporteSemanal/ReporteSemanal";
import Padron from "./pages/padron/Padron";
import ApartadoA from "./pages/apartadoA/ApartadoA";
import ApartadoB from "./pages/apartadoB/ApartadoB";
import Facturacion from "./pages/facturacion/Facturacion";
// consts
import { Movimientos, Routes, Tarjetas, Limites, Reportes } from "./consts/Routes-App";
//Credentials services
import { usuarioMinimosGet } from './services/hooli-services/UsuarioMinimosGet';
import { getUserCredentials } from "./services/hooli-services/GetUserCredentials";
import { checkAuth } from "./services/hooli-services/checkAuth";
// recoil
import { useRecoilState, useSetRecoilState } from 'recoil';
import { userInfo, userCredentials, routeInfo } from './recoilState/GlobalState';
import 'dotenv/config'
import RG8125 from "./pages/rg8125/RG8125";
import RG8126 from "./pages/rg8126/RG8126";

export default function App() {
    const [credentials, setCredentials] = useRecoilState(userCredentials);
    const setInfo = useSetRecoilState(userInfo);
    const setRoute = useSetRecoilState(routeInfo);

    useEffect(() => {
        (async () => {
            const data = checkAuth();
            // const data = await getUserCredentials();
            setCredentials(data);
            if (window.location.search) window.history.replaceState({}, document.location, '/');
            const routes = { ...Routes, ...Movimientos, ...Tarjetas, ...Limites, ...Reportes }

            if (routes[window.location.pathname.split('/')[1]]) setRoute(routes[window.location.pathname.split('/')[1]])
        })();
    }, []);

    useEffect(() => {
        async function retrieveUsuarioMinimo() {
            const data = await usuarioMinimosGet(credentials)
            if (data) setInfo(data)
        };
        if (Object.keys(credentials).length > 0) retrieveUsuarioMinimo()
    }, [credentials]);

    return (
        <Router>
            <Header />
            <SnackBar />
            <Switch>
                {/* Dashboard */}
                <Route path={Routes.dashboard.route} exact render={() => (<Dashboard route={Routes.dashboard} />)} />
                {/* Balance */}
                <Route path={Routes.balance.route} exact render={() => (<Balance route={Routes.balance} />)} />
                {/* Conciliaciones */}
                <Route path={Routes.conciliacionesBind.route} exact render={() => (<Conciliaciones />)} />
                <Route path={Routes.conciliacionesGIRE.route} exact render={() => (<ConciliacionesGIRE />)} />
                {/* Liquidaciones */}
                <Route path={Routes.liquidacionesPrisma.route} exact render={() => (<LiquidacionesPrisma route={Routes.liquidacionesPrisma} />)} />
                {/* Tablas */}
                <Route path={Routes.feriados.route} exact render={() => (<Feriados />)} />
                <Route path={Routes.seriesDeFeriados.route} exact render={() => (<SerieDeFeriados />)} />
                {/* Tarjetas */}
                <Route path={Tarjetas.tarjetasEmisores.route} exact render={() => (<TarjetasEmisores />)} />
                <Route path={Tarjetas.tarjetasPlasticos.route} exact render={() => (<TarjetasPlasticos />)} />
                <Route path={Tarjetas.tarjetasMarcas.route} exact render={() => (<TarjetasMarcas />)} />
                <Route path={`${Tarjetas.tarjetasCoeficientes.route}/:marcaId`} exact render={() => (<TarjetasCoeficientes />)} />
                <Route path={Tarjetas.tarjetasTransacciones.route} exact render={() => (<TarjetasTransacciones />)} />
                <Route path={Tarjetas.tarjetasAcreditacionMerchant.route} exact render={() => (<TarjetasAcreditaciones />)} />
                <Route path={Tarjetas.tarjetasConciliacionGW.route} exact render={() => (<TarjetasConciliaciones />)} />
                {/* Movimientos */}
                <Route path={Movimientos.promosMarketing.route} exact render={() => (<PromosMarketing route={Movimientos.promosMarketing} />)} />
                {/* Limites */}
                <Route path={Limites.limites.route} exact render={() => (<LimitesDeCondicionesAFIP />)} />
                <Route path={Limites.control.route} exact render={() => (<ControlLimites />)} />
                {/* Reportes */}
                <Route path={Reportes.semanal.route} exact render={() => (<ReporteSemanal />)} />
                <Route path={Reportes.padron.route} exact render={() => (<Padron />)} />
                <Route path={Reportes.apartadoA.route} exact render={() => (<ApartadoA />)} />
                <Route path={Reportes.apartadoB.route} exact render={() => (<ApartadoB />)} />
                <Route path={Reportes.facturacion.route} exact render={() => (<Facturacion />)} />
                <Route path={Reportes.rg8125.route} exact render={() => (<RG8125 />)} />
                <Route path={Reportes.rg8126.route} exact render={() => (<RG8126 />)} />
            </Switch>
        </Router>
    );
};