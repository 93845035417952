export const Routes = {
    dashboard: {
        route: "/",
        wordKey: "Dashboard",
    },
    balance: {
        route: "/balance",
        wordKey: "Balance",
    },
    conciliacionesBind: {
        route: "/conciliacionesBind",
        wordKey: "Conciliación BIND",
    },
    conciliacionesGIRE: {
        route: "/conciliacionesGIRE",
        wordKey: "Conciliación GIRE",
    },
    liquidacionesPrisma: {
        route: "/liquidaciones-prisma",
        wordKey: "Liquidaciones Prisma",
    },
    feriados: {
        route: "/feriados",
        wordKey: "Feriados",
    },
    seriesDeFeriados: {
        route: "/seriesDeFeriados",
        wordKey: "Series de feriados",
    },
};

export const Movimientos = {
    promosMarketing: {
        route: '/promos-marketing',
        wordKey: "Promos Marketing",
    } 
};

export const Tarjetas = {
    tarjetasTransacciones: {
        route: "/tarjetasTransacciones",
        wordKey: "Transacciones de pago con tarjeta",
    },
    tarjetasAcreditacionMerchant: {
        route: "/tarjetasAcreditacionMerchant",
        wordKey: "Acreditacion al merchant de pagos con tarjeta",
    },
    tarjetasConciliacionGW: {
        route: "/tarjetasConciliacionGW",
        wordKey: "Conciliacion con GW",
    },
    tarjetasEmisores: {
        route: "/tarjetasEmisores",
        wordKey: "Emisores de tarjeta",
    },
    tarjetasMarcas: {
        route: "/tarjetasMarcas",
        wordKey: "Marcas de tarjeta",
    },
    tarjetasPlasticos: {
        route: "/tarjetasPlasticos",
        wordKey: "Plasticos de tarjeta",
    },
    tarjetasCoeficientes: {
        route: "/tarjetasCoeficientes",
        wordKey: "Coeficientes de tarjeta",
    }
};

export const Limites = {
    limites: {
        route: '/limites',
        wordKey: "Límites de condiciones AFIP",
    },
    control: {
        route: '/control',
        wordKey: 'Control de límites'
    }
};

export const Reportes = {
    semanal: {
        route: '/semanal',
        wordKey: "Reporte semanal DB/CR",
    },
    apartadoA: {
        route: '/apartadoA',
        wordKey: "Reporte mensual Apartado A",
    },
    apartadoB: {
        route: '/apartadoB',
        wordKey: "Reporte mensual Apartado B",
    },
    padron: {
        route: '/padron',
        wordKey: "Reporte mensual Padrón",
    },
    facturacion: {
        route: '/facturacion',
        wordKey: "Reporte mensual Facturación",
    },
    rg8125: {
        route: '/rg8125',
        wordKey: "Reporte mensual RG8125",
    },
    rg8126: {
        route: '/rg8126',
        wordKey: "Reporte mensual RG8126",
    },
};