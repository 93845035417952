// Balance
export const balance = ["Cuenta", "Saldo anterior", "Débitos", "Créditos", "Saldo Actual"];
export const modalBalance = ["Cuenta", "Saldo anterior", "Débitos", "Créditos", "Saldo Actual"];
export const modalResumen = ["Fecha", "Concepto", "Débito", "Crédito", "Saldo", "Contraparte", "Referencia", "ID Coelsa", "Comprobante", "Mov. Adicional"];
// Conciliaciones
export const conciliaciones = ["Tipo de movimiento", "ID de conciliación", "Monto", "Fecha", "Hooli CUIT Origen", "Hooli CUIT Destino", "Monto Bind", "Fecha Bind", "Bind CUIT Origen", "Bind CUIT Destino"];
export const conciliacionGIRE = ["ID conciliación", "ID operación Hooli", "ID transaccion Hooli", "Monto Hooli", "Fecha Hooli", "Empresa Hooli", "ID operación Gire", "ID transaccion Gire", "Monto Gire", "Fecha Gire", "Empresa Gire"];
// Prisma
export const liquidacionesPrisma = ["Fecha de emisión", "Nro de resumen", "Nro de establecimiento", "Total presentado", "Total descuento", "Saldo"]
// Tarjetas
export const emisores = ["Nombre", "Codigo", "Bancario / No bancario"];
export const plasticos = ["Numero", "Marca", "Emisor", "Tipo", "Codigo Line"];
export const tarjetasMarcas = ["Nombre", "Crédito / Débito", "Codigo En Line", "Codigo", "Coeficientes"];
export const coeficientes = ["Cantidad cuotas", "C/I Vigente desde", "C/I Activo", "C/I Valor", "S/I Vigente desde", "S/I Activo", "S/I Valor", "Hooli Vigente desde", "Hooli Valor"];
export const transacciones = ["Emisión", "Autorización", "Acreditación", "Baja", "Merchant", "Monto de Venta", "Monto", "Marca", "Cliente no hooli", "Cliente hooli", "Presente", "Tipo", "Origen", "Emisor", "Cuotas", "Estado"]
export const acreditaciones = ["Nombre Merchant", "Monto", "Total operaciones"];
export const acreditacionDetalle = ["Fecha", "Lote", "Cupon", "Monto comercio", "Fee Hooli", "Fee transacción", "Fee Cuotas","IVA", "IVA Cuotas", "Débito / Crédito", "Monto pagado"]
export const tarjetasConciliaciones = ["Fecha", "Monto", "Lote", "Cupon", "Ultimos 4"];
// Tablas
export const feriados = ["Nombre"];
export const seriesDeFeriados = ["Fecha", "Comercial", "Bancario"];
// Limites
export const limites = ["Condición AFIP", "Valido desde", "Monto diario", "Cantidad diaria", "Monto mensual", "Cantidad mensual"];
export const controlLimites = ["Nombre", "CUIT", "Condición AFIP", "Monto diario", "Cantidad diaria", "Monto mensual", "Cantidad mensual"];