import axiosPost from "../axiosPost";

export const AFIPRG416Get = async (arg) => {
  const { params } = arg;
  const body = {
    service: "AFIPRG416Get",
    params: params ? params : {},
  };

  try {
    const result = await axiosPost("Y", body, arg);
    return result.data.result;
  } catch (err) {
    console.log(err);
  }
};
