import React, { useState } from "react";
// styles
import "../pages.css";
// recoil
import { useRecoilValue } from "recoil";
import { userCredentials } from "../../recoilState/GlobalState";
import * as yup from "yup";
import {
  FormikMonthInput,
} from "../../components/utils/inputs/FormikInputs";
import { useFormik } from "formik";
import { Button } from "@material-ui/core";
import { FormButtonsStyle } from "../../components/utils/button/FormButtons.styles";
import { exportarCSV } from "../../services/Local-services";
import { apartadoBGet } from "../../services/hooli-services/Reportes/ApartadoBGet";

export default function ApartadoB() {
  const [cargando, setCargando] = useState(false);
  const [error, setError] = useState("");
  const credentials = useRecoilValue(userCredentials);

  const formik = useFormik({
    initialValues: {
        mes: new Date().toISOString().substring(0, 7)
    },
    validationSchema: yup.object({
      mes: yup.date().required("Mes es requerido"),
    }),
    onSubmit: async ({ mes }) => {
      setCargando(true);
      const fechaDesde = mes + '-01'
      const hasta = new Date(fechaDesde);
      const fechaHasta = new Date(hasta.getFullYear(), hasta.getMonth() + 2, 0).toISOString().substring(0, 10);
      const payload = {
        ...credentials,
        fechaDesde,
        fechaHasta
      }
      const response = await apartadoBGet(payload);
      if (!response.length) {
        setError("Sin resultados");
      } else {
        setError("")
      }
      exportarCSV(response, `ApartadoB-${mes}`);
      setCargando(false);
    },
  });

  const classes = FormButtonsStyle();

  const data = { mes: { label: "Mes" } };

  return (
    <main>
      <form
        onSubmit={formik.handleSubmit}
        style={{ display: "flex", alignItems: "center" }}
      >
        <FormikMonthInput
          data={data}
          formik={formik}
          disabled={cargando}
          name="mes"
          className={classes.marginRight}
        />
        <Button
          className={classes.defaultBtn}
          type="submit"
          disabled={cargando}
        >
          Generar reporte
        </Button>
      </form>
      {error && !cargando && <p>{error}</p>}
    </main>
  );
}
