import axiosPost from "../axiosPost";

export const facturacionGet = async (arg) => {
  const { fechaDesde, fechaHasta } = arg;
  const body = {
    service: "ADFacturacionGet",
    params: { fechaDesde, fechaHasta },
  };

  try {
    const result = await axiosPost("Y", body, arg);
    return result.data.result;
  } catch (err) {
    console.log(err);
  }
};
