export const modalsInfoConciliations = {
    conciliar: {
        title: "Agregar Observación",
        button: "Confirmar"
    },
    reversos: {
        title: "Reversar",
        button: "Confirmar"
    },
    desconciliar: {
        title: "Agregar Observación",
        button: "Confirmar"
    },
    ocultar: {
        title: "Agregar Observación",
        button: "Confirmar"
    },
    revertir: {
        title: "Agregar Observación",
        button: "Confirmar"
    },
    revisar: {
        title: "Agregar Observación",
        button: "Confirmar"
    },
    archivo: {
        title: "Importar archivo",
        button: "Importar"
    },
    acreditar: {
        title: "Confirmar acreditación",
        button: "Acreditar"
    }
};

export const modalsInfoConciliationsGIRE = {
    archivo: {
        title: "Importar archivo",
        button: "Importar"
    },
    ocultar: {
        title: "Añadir comentario",
        button: "Confirmar"
    },
    revisar: {
        title: "Añadir comentario",
        button: "Confirmar"
    },
    revertir: {
        title: "Añadir comentario",
        button: "Confirmar"
    },
    desconciliar: {
        title: "Añadir comentario",
        button: "Confirmar"
    },
    conciliar: {
        title: "Añadir comentario",
        button: "Confirmar"
    },
};

export const modalsInfoEmisores = {
    add: {
        title: "Agregar Emisor de tarjeta",
        nombre: { label: "Emisor", placeholder: "Ingrese emisor" },
        codigo: { label: "Codigo", placeholder: "Ingrese codigo" },
        bancario: { label: "Es bancario" },
        button: "Agregar"
    },
    edit: {
        title: "Editar Emisor de tarjeta",
        nombre: { label: "Emisor" },
        codigo: { label: "Codigo" },
        bancario: { label: "Es bancario" },
        button: "Guardar cambios"
    },
    delete: {
        title: "Eliminar Emisor de tarjeta",
        nombre: { label: "Emisor" },
        codigo: { label: "Codigo" },
        bancario: { label: "Es bancario" },
        button: "Eliminar"
    },
};

export const modalsInfoAcreditaciones = {
    confirmar: {
        title: "Acreditar al merchant",
        button:  "Acreditar"
    },
    detalle: {
        title: "Detalle",
        button:  ""
    }
};

export const modalsInfoPlasticos = {
    add: {
        title: "Agregar Plastico de tarjeta",
        numero: { label: "Numero", placeholder: "Ingrese numero" },
        marca: { label: "Marca" },
        emisor: { label: "Emisor" },
        codigo: { label: "Codigo Line", placeholder: "Ingrese codigo" },
        button: "Agregar"
    },
    edit: {
        title: "Editar Plastico de tarjeta",
        numero: { label: "Plastico" },
        marca: { label: "Marca" },
        emisor: { label: "Emisor" },
        codigo: { label: "Codigo Line" },
        button: "Guardar cambios"
    },
    delete: {
        title: "Eliminar Plastico de tarjeta",
        numero: { label: "Plastico" },
        marca: { label: "Marca" },
        emisor: { label: "Emisor" },
        tipo: { label: "Tipo" },
        codigo: { label: "Codigo Line" },
        button: "Eliminar"
    },
};

export const modalsInfoMarcas = {
    add: {
        title: "Agregar Marca de tarjeta",
        nombre: { label: "Marca", placeholder: "Ingrese marca" },
        tipo: { label: "Credito / Debito" },
        codigo: { label: "Codigo Line", placeholder: "Ingrese codigo" },
        marcaCod: { label: "Codigo", placeholder: "Ingrese codigo" },
        button: "Agregar"
    },
    edit: {
        title: "Editar Marca de tarjeta",
        nombre: { label: "Marca" },
        tipo: { label: "Credito / Debito" },
        codigo: { label: "Codigo Line" },
        marcaCod: { label: "Codigo" },
        button: "Guardar cambios"
    },
    delete: {
        title: "Eliminar Marca de tarjeta",
        nombre: { label: "Marca" },
        tipo: { label: "Credito / Debito" },
        codigo: { label: "Codigo Line" },
        marcaCod: { label: "Codigo" },
        button: "Eliminar"
    },
};

export const modalsInfoCoeficientes = {
    add: {
        title: "Agregar Coeficiente de tarjeta",
        cuotas: { label: "Cantidad de cuotas" },
        tipo: { label: "Tipo" },
        valor: { label: "Valor", placeholder: "Ingrese valor" },
        vigencia: { label: "Vigencia desde" },
        button: "Agregar"
    }
};

export const modalsInfoFeriados = {
    add: {
        title: "Agregar Feriado",
        nombre: { label: "Feriado", placeholder: "Ingrese feriado" },
        button: "Agregar"
    },
    edit: {
        title: "Editar Feriado",
        nombre: { label: "Feriado" },
        button: "Guardar cambios"
    },
    delete: {
        title: "Eliminar Feriado",
        nombre: { label: "Feriado" },
        button: "Eliminar"
    },
};

export const modalsInfoSerieDeFeriados = {
    add: {
        title: "Agregar serie de feriados",
        fecha: { label: "Feriado" },
        comercial: { label: "Comercial" },
        bancario: { label: "Bancario" },
        button: "Agregar"
    },
    edit: {
        title: "Editar serie de feriados",
        fecha: { label: "Feriado" },
        comercial: { label: "Comercial" },
        bancario: { label: "Bancario" },
        button: "Guardar cambios"
    },
    delete: {
        title: "Eliminar serie de feriados",
        fecha: { label: "Feriado" },
        comercial: { label: "Comercial" },
        bancario: { label: "Bancario" },
        button: "Eliminar"
    },
};

export const formInfoPromosMarketing = {
    hooliCoins: {
        label: 'Hooli Coins',
        placeholder: 'Inserte una cantidad de hooli coins'
    },
    fecha: {
        label: 'Fecha'
    }
};

export const modalsInfoPromosMarketing = {
    title: 'Confirmar transferencia',
    button: "Confirmar"
};

export const modalsInfoLimites = {
    add: {
        title: "Agregar Límite",
        fecha: { label: "Fecha" },
        condicionAFIP: { label: "Condición AFIP" },
        diaMonto: { label: "Monto diario" },
        diaCantidad: { label: "Cantidad diaria" },
        mesMonto: { label: "Monto mensual" },
        mesCantidad: { label: "Cantidad mensual" },
        button: "Agregar"
    },
    edit: {
        title: "Editar Límite",
        fecha: { label: "Fecha" },
        condicionAFIP: { label: "Condición AFIP" },
        diaMonto: { label: "Monto diario" },
        diaCantidad: { label: "Cantidad diaria" },
        mesMonto: { label: "Monto mensual" },
        mesCantidad: { label: "Cantidad mensual" },
        button: "Guardar cambios"
    },
    delete: {
        title: "Eliminar Límite",
        fecha: { label: "Fecha" },
        condicionAFIP: { label: "Condición AFIP" },
        diaMonto: { label: "Monto diario" },
        diaCantidad: { label: "Cantidad diaria" },
        mesMonto: { label: "Monto mensual" },
        mesCantidad: { label: "Cantidad mensual" },
        button: "Eliminar"
    },
};

export const modalsInfoControlLimites = {
    edit: {
        title: "Editar Límites",
        diaMonto: { label: "Monto diario" },
        diaCantidad: { label: "Cantidad diaria" },
        mesMonto: { label: "Monto mensual" },
        mesCantidad: { label: "Cantidad mensual" },
        button: "Guardar cambios"
    }
};