import React, { useState } from "react";
// styles
import "../pages.css";
// recoil
import { useRecoilValue } from "recoil";
import { userCredentials } from "../../recoilState/GlobalState";
import * as yup from "yup";
import { FormikDateInput } from "../../components/utils/inputs/FormikInputs";
import { useFormik } from "formik";
import { AFIPRG416Get } from "../../services/hooli-services/Reportes/AFIPRG416Get";
import { exportarCSV, exportarTXT } from "../../services/Local-services";
import { Button } from "@material-ui/core";
import { FormButtonsStyle } from "../../components/utils/button/FormButtons.styles";

export default function ReporteSemanal() {
  const [cargando, setCargando] = useState(false);
const [error, setError] = useState('')
  const credentials = useRecoilValue(userCredentials);

  const fechaDesde = new Date()
  fechaDesde.setDate(fechaDesde.getDate() - 6)
  const formik = useFormik({
    initialValues: {
      hasta: new Date().toISOString().substring(0, 10),
      desde: fechaDesde.toISOString().substring(0, 10)
    },
    validationSchema: yup.object({
      hasta: yup
        .date()
        .max(
          new Date().toISOString().substring(0, 10),
          "No puede ser una fecha futura"
        ),
        desde: yup.date().required('Fecha desde es requerida')
    }),
    onSubmit: async ({ hasta, desde }) => {
        setCargando(true);
        const fechaDesde = new Date(hasta);
        fechaDesde.setDate(fechaDesde.getDate() - 6);
        if (fechaDesde.toISOString().substring(0, 10) !== desde ){
            setError('La diferencia entre ambas fechas debe ser de 6 días')
            setCargando(false);
            return
        }
        const payload = {
            params: {
                fechaHasta: hasta,
                fechaDesde: fechaDesde.toISOString().substring(0, 10),
            },
            ...credentials,
      };
      payload.params.csv = "Y";
      const csvResult = await AFIPRG416Get(payload);
      payload.params.csv = "N";
      payload.params.txt = "Y";
      const txtResult = await AFIPRG416Get(payload);
      if (!csvResult.length && !txtResult.length){
        setError('Sin resultados')
    } else {
        setError('')
    }
    exportarCSV(csvResult, "Reporte DB/CR");
      exportarTXT(txtResult, "Reporte DB/CR");
      setCargando(false);
    },
});

  const classes = FormButtonsStyle();

  const data = { desde: {label: "Desde"}, hasta: { label: "Hasta" } };

  return (
    <main>
      <form
        onSubmit={formik.handleSubmit}
        style={{ display: "flex", alignItems: "center" }}
      >
        <FormikDateInput
          data={data}
          formik={formik}
          disabled={cargando}
          name="desde"
          className={classes.marginRight}
        />
        <FormikDateInput
          data={data}
          formik={formik}
          disabled={cargando}
          name="hasta"
          className="block"
        />
        <Button className={classes.defaultBtn} type="submit" disabled={cargando}>
          Generar reporte
        </Button>
      </form>
      {error && !cargando && 
        <p>{error}</p>
      }
    </main>
  );
}
